import React, { useEffect, useState } from "react";
import { Grid, Container, Typography, Button, Box } from "@material-ui/core";
import { Player } from "@lottiefiles/react-lottie-player";
import anim from "../../../../assets/anim/payment-success.json";

import copy from "copy-to-clipboard";
import { Navigate, Link } from "react-router-dom";
import { useStyles } from "./style";
import { setTheme } from "../../../../tools/Tools";
let setIsTheme = false;
let textBtnRedirect = "بازگشت به سایت پذیرنده ";

const PaymentSuccess = ({ setLogo, setNavigate }) => {
  const classes = useStyles();
  const [isRedirect, setIsRedirect] = useState();
  const [infoSite, setInfoSite] = useState({
    depositId: "",
    voucher: null,
    depositId: "",
    redirectUrl: "",
    websiteName: ""

  });
  const [click, setClick] = useState(false);
  const [enableBtnCopy, setEnableBtnCopy] = useState(false);
  React.useEffect(() => {

    if (!JSON.parse(window.sessionStorage.getItem("isSuccessIPG")))
      setNavigate("loading")
    if (JSON.parse(sessionStorage.getItem("webSiteInfo")))
      setInfoSite({
        depositId: window.sessionStorage.getItem("depositId"),
        voucher: sessionStorage.getItem("voucher"),
        redirectUrl: sessionStorage.getItem("redirectUrl"),
        websiteName: JSON.parse(sessionStorage.getItem("webSiteInfo"))
          .websiteName
      })
    if (!setIsTheme) {
      setIsTheme = true;
      setTheme(setLogo);

      // document.getElementById(
      //   "background"
      // ).style.backgroundColor = `#4CAF50`;
    }
  }, []);




  useEffect(() => {
    if (setIsTheme) {
      setIsRedirect(true)
      let in_ = sessionStorage.getItem("autoRedirect");
      if (in_ != null) {
        if (JSON.parse(in_)) {
          setIsRedirect(true);
          setTimeout(() => {
            window.location = sessionStorage.getItem("redirectUrl");
          }, 4000);
        } else {
          setIsRedirect(false);
        }
      }
    }
  }, []);
  return (

    <Box style={{ marginTop: "40px", paddingBottom: 20 }}>
      <Grid
        container
        spacing={0}
        className={classes.boxMain}
        justify="center"
        direction="row"
        xs={12}
      >
        <Grid
          item
          xs={11}
          style={{
            textAlign: "center",
            marginTop: "23px",
            fontFamily: "IRANSans",
            background: "#4CAF50",
            borderRadius: 8,
            paddingTop: 8,
            paddingBottom: 8,
            color: "white"
          }}
        >
          پرداخت با موفقیت انجام شد
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            textAlign: "center",
            marginTop: "10px",
            fontFamily: "title3 !important",
            fontStyle: "normal",
            color: "#ff6f00",
            fontSize: 13
          }}
          className="fade_2"
        >
          لطفا تا اتمام عملیات منتظر بمانید و از این صفحه خارج نشوید
        </Grid>
        <Grid item xs={12}>
          <Player
            autoplay
            loop
            src={anim}
            style={{
              height: "250px",
              width: "250px",
              marginBottom: "15px",
              marginTop: "15px",
            }}
          />
        </Grid>
        <Grid
          container
          direction="row"
          justify="center"
          alignContent="center"
          alignItems="center"
          style={{ textAlign: "center", direction: "ltr", marginTop: 25 }}
        >
          <span>
            {" "}
            &nbsp; &nbsp; {infoSite.depositId}{" "}
            &nbsp; &nbsp;{" "}
          </span>
          <span
            style={{ fontSize: "13px", color: "grey" }}
            className="un-selected"
          >
            : شناسه پیگیری{" "}
          </span>
        </Grid>

        {infoSite.voucher && (
          <Grid
            xs={10}
            container
            direction="row"
            justify="center"
            alignContent="center"
            alignItems="center"
            style={{
              background: "#d2fee7",
              borderRadius: "12px",
              paddingBottom: 15,
              marginTop: 10,
            }}
          >
            <Grid
              item
              xs={10}
              style={{
                color: "#000b27",
                textAlign: "center",
                marginTop: 15,
                marginBottom: 8,
              }}
            >
              : کد ووچر
            </Grid>
            <Grid
              container
              xs={8}
              direction="row"
              justify="flex-start"
              alignContent="center"
              alignItems="center"
              className={classes.codeLayout}
            >
              <Grid item>
                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  fullWidth
                  color="secondary"
                  className={classes.submitCopy}
                  onClick={() => {
                    copy(infoSite.voucher);
                    setEnableBtnCopy(true);
                  }}
                >
                  {!enableBtnCopy ? " کپی" : "  کد کپی شد"}
                </Button>
              </Grid>
              <Grid item style={{ marginLeft: 15 }}>
                <span className='codeVocher'>{infoSite.voucher}</span>

              </Grid>
            </Grid>
          </Grid>
        )}

        {isRedirect && (
          <Grid
            item
            xs={12}
            style={{ color: "grey", textAlign: "center", fontSize: "13px" }}
          >
            ...تا لحظاتی دیگر به سایت پذیرنده هدایت میشوید
          </Grid>
        )}

        <Grid
          container
          xs={12}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Button
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.submit}
          >

            <a
              href={infoSite.redirectUrl}
              style={{
                color: "white",
                textDecoration: "none",
                direction: "rtl",
              }}
              target="_self"
            >
              {" "}
              {isRedirect
                ? textBtnRedirect
                : `بازگشت به ${infoSite.websiteName} `}{" "}
            </a>
          </Button>
        </Grid>
      </Grid>
    </Box>

  );
};
export default PaymentSuccess;
