import React, { Component, useState } from "react";
import {
  Grid,
  Button,
  Box,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { Navigate, Link } from "react-router-dom";
import useStyles from "./Style";
import { setTheme, replaceAllEnter, normalizeText } from "../../../../tools/Tools";
import Api from "../../../../api/Api";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DialogError from "../dialogs/DialogError";
import DialogSuccess from "../dialogs/DialogSuccess";
import LoadingProcess from "../LoadingProcess";
import { showErrorRequest } from './../../../../tools/Tools';



const Help = ({ setLogo, setNavigate }) => {
  const classes = useStyles();
  const [cardNumber, setCardNumber] = useState(null);
  const [loading, setloading] = useState(false);
  const [isResult, setIsResult] = useState({
    result: false,
    msg: "",
    isSuccess: 0, // 0:error 1: warring 2: success
  });

  React.useEffect(() => {
    setTheme(setLogo);
  }, []);
  const [msg, setMsg] = useState("خطا از سمت سرور ");

  const handleButtonSubmit = () => {
    if (cardNumber && cardNumber.length > 15) {
      setloading(true);
      setTimeout(() => {
        requestAddCard();
      }, 350);
    } else {
      setIsResult({
        ...isResult,
        result: true,
        msg: "شماره کارت را وارد کنید",
      });
    }
  };
  const handleCloseDialog = () => {
    setIsResult({ result: false });
  };
  const handleCloseSuccessDialog = () => {
    window.sessionStorage.setItem(
      "userCards",
      JSON.stringify(isResult.userCards)
    );
    window.sessionStorage.setItem("isMsgAddCard", true);

    setIsResult({ result: false });

    setTimeout(() => {
      if (JSON.parse(window.sessionStorage.getItem("addCard_StepStep")))
        goToNext()
      else if (JSON.parse(window.sessionStorage.getItem("isSelectMethodAddCard")))
        setNavigate("selectMethod");
      else setNavigate("MsgAddCardPage");
    }, 300);
  };
  const goToNext = () => {

    if (window.sessionStorage.getItem("sessionType") == "withdrawal")
      setNavigate("homePageWithdrawal")
    else if (window.sessionStorage.getItem("method") == "CPG")
      setNavigate("home")
    else if (window.sessionStorage.getItem("method") == "IPG") setNavigate("homeIPG")
    else
      setNavigate("homeC2C")
  }

  const requestAddCard = async () => {
    try {
      const { status, data } = await Api.addCard(cardNumber);

      if (status === 200) {
        if (data.success) {
          sessionStorage.setItem('message', data.message)
          setIsResult({
            ...isResult,
            result: true,
            msg: data.message,
            isSuccess: 2,
            userCards: data.userCards,
          });
        } else {
          setIsResult({ ...isResult, result: true, msg: data.message });
        }
      } else {
        setIsResult({ ...isResult, result: true, msg: showErrorRequest(status) });
      }
      setloading(false);
    } catch (error) {
      setloading(false);
      setIsResult({ ...isResult, result: true, msg: showErrorRequest(error) });
    }
  };


  return (
    <Box style={{ paddingTop: "50px", marginBottom: 10 }}>

      <Grid
        container
        spacing={0}

        className={`${classes.boxMain} ${(loading || isResult.result) && "blurBackground"
          }`}
        style={{ position: "relative" }}
      >
        <LoadingProcess loading={loading} />
        <Grid container direction="row" justify="flex-start" xs={8}>

          <Button
            size="small"
            type="submit"
            variant="text"
            color="primary"
            onClick={goToNext}
            className={classes.backBtn}
            startIcon={<ArrowBackIosIcon />}
          >
            بازگشت
          </Button>

        </Grid>

        <Grid
          item
          className={`${classes.layoutTitle} backgroundTitle un-selected`}
          justify="center"
          justifyContent="center"
          xs={4}
        >
          راهنمای درگاه
        </Grid>



        <Grid
          container
          xs={12}
          direction="row"
          justify="flex-start"
          alignItems="center"
          style={{
            textAlign: "right",
            marginTop: "23px",
            fontFamily: "IRANSans",
            direction: "rtl",
            marginBottom: 10,
            paddingRight: 15,
            paddingLeft: 15


          }}
        >




          {window.sessionStorage
            .getItem("helpMessage")
            .split("\n")
            .map(function (item, idx) {



              return (
                <p key={idx} style={{ width: "100%" }}>
                  {normalizeText(replaceAllEnter(item))}
                </p>
              );
            })}
        </Grid>


      </Grid>

      {isResult.isSuccess == 2 ? (
        <DialogSuccess
          isResult={isResult}
          handleCloseDialog={handleCloseSuccessDialog}
        />
      ) : (
        <DialogError
          isResult={isResult}
          handleCloseDialog={handleCloseDialog}
          setNavigate={setNavigate}
        />
      )}
    </Box>
  );
};
export default Help;
