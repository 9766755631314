import React, { Component, useEffect, useState } from "react";
import {
  Grid,
  CardContent,
  Button,
  Box,
  Collapse,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Checkbox,
  Avatar,
  CircularProgress,
} from "@material-ui/core";

import { useLocation } from "react-router-dom";
import {
  getLogo,
  getNumberCardFromatter,
} from "../../cpg/addCardPage/ListBank";

import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { useStyles } from "./style";
import Timer from "./Timer";
import {
  setTheme,
  getListYear,
  getArrayNumberCard,
  convertNumberToPice,
  convertNumbers2English,
  resulation,
  replaceAllEnter,
  setNumberCard,
  showErrorRequest
} from "../../../../tools/Tools";
import LoadingCircleProcess from "../../cpg/LoadingCircleProcess";
import DialogSuccess from "../../cpg/dialogs/DialogSuccess";
import DialogError from "../../cpg/dialogs/DialogError";
import DialogWarring from "../../cpg/dialogs/DialogWarring";
import Api from "../../../../api/Api";
import CardBank from "../CardBank"
import copy from "copy-to-clipboard";
import DialogLoading from '../dialogs/DialogLoading2'
import { changeProcessC2C, resetProcessC2C } from "../../../../redux/actions";
import { connect } from "react-redux";
let time;
let setIsTheme = false;
let loackRequestCancel = false;

const LastStepPage = ({ setLogo, changeProcessC2C, resetProcessC2C, setNavigate }) => {
  const { state } = useLocation();
  const classes = useStyles();
  const [formData, setFormData] = useState();
  const [loading, setloading] = useState(false);
  const [enableBtnCopy, setEnableBtnCopy] = useState(false);
  const [redirectUrl, setRedirectUrl] = React.useState();
  const [timeForm, setTimeForm] = React.useState();
  const [endProcess, setEndProcess] = React.useState(false);
  const [websiteInfo, setWebsiteInfo] = useState({});
  const [isResult, setIsResult] = useState({
    result: false,
    msg: "",
    isSuccess: false,
  });

  const myRef = React.useRef(null);
  const myRefMonth = React.useRef(null);
  const [msg, setMsg] = useState("خطا از سمت سرور ");
  const [age, setAge] = React.useState("5");


  const handleCloseDialogError = () => {

    if (isResult.isReset || isResult.endTimeFrom) {
      const url = sessionStorage.getItem("url_")
      const sessionId = sessionStorage.getItem("sessionId")
      localStorage.clear();
      sessionStorage.clear();
      sessionStorage.setItem("url_", url)
      sessionStorage.setItem("sessionId", sessionId)
    }
    setIsResult({ ...isResult, result: false });
  };




  const handleBtnCancel = () => {

    setIsResult({
      result: true,
      msg: "آیا از لغو تراکنش اطمینان دارید؟",
      isSuccess: 1,
    });
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 6 + ITEM_PADDING_TOP,
      },
    },
  };
  const requestCancelDeposit = async () => {
    loackRequestCancel = true
    try {
      const { data } = await Api.cancelDeposit();
      const url = sessionStorage.getItem("url_")
      const sessionId = sessionStorage.getItem("sessionId")
      localStorage.clear();
      sessionStorage.clear();
      sessionStorage.setItem("url_", url)
      sessionStorage.setItem("sessionId", sessionId)
      setIsResult({ result: false });
      setNavigate("loading")
      loackRequestCancel = false
    } catch (error) {
    }
  };


  const handleCloseAndBackDialogWarring = () => {
    if (!loackRequestCancel)
      requestCancelDeposit();
  };
  const handleCloseDialogWarring = () => {
    setIsResult({ result: false });
  };
  const handleCloseDialogSuccess = () => {
    setIsResult({ result: false });
    if (isResult?.timer) {
      time = new Date();
      time.setSeconds(time.getSeconds() + Number(isResult?.timer));

      setTimeout(() => {
        myRef.current.focus();
      }, 700);
    } else {
      if (isResult?.redirectUrl) {
        window.sessionStorage.setItem(
          "redirectUrl",
          JSON.stringify(isResult?.redirectUrl)
        );
        setNavigate("paymentSuccess")

        // setIsResult({  result: true, msg: 'پرداخت با موفقیت انجام شد',isSuccess:2,redirectUrl:'www.google.com'});
      }
    }
  };

  const handleBtnSubmit = () => {

    let countSubmit = JSON.parse(window.sessionStorage.getItem("countSubmit"))
    let countReq = JSON.parse(window.sessionStorage.getItem("countReq"))

    if (countSubmit) {
      if (countReq == null)
        window.sessionStorage.setItem("countSubmit", countSubmit + 1);
    } else {
      countSubmit = 1
      window.sessionStorage.setItem("countSubmit", 1);
    }


    if (countSubmit <= 2) {
      setloading(true);

      if (Number.isInteger(countReq))
        countReq = countReq + 1
      else
        countReq = 1


      window.sessionStorage.setItem(
        "countReq", countReq
      );
      requestTransfer();
    } else {

      setIsResult({ result: true, isSuccess: 0, msg: "درخواست شما از ابتدا شروع میشود", isReset: true })
    }


  };

  const requestTransfer = async () => {
    try {


      const { status, data } = await Api.transferC2C();

      if (status === 200) {

        if (data.success) {

          sessionStorage.setItem('isSuccessCPG', true)
          sessionStorage.setItem('autoRedirect', data.autoRedirect)
          if (data.showVoucher)
            sessionStorage.setItem('voucher', data.voucher)
          window.sessionStorage.setItem("depositId", data.depositId);
          window.sessionStorage.setItem("redirectUrl", data.redirectUrl);
          setNavigate("paymentSuccess")


          // window.sessionStorage.setItem("depositId", data.depositId);
          // // window.sessionStorage.setItem(
          // //   "isSuccessC2C", true
          // // );
          // window.sessionStorage.setItem(
          //   "redirectUrl",
          //   JSON.stringify(data.websiteInfo)
          // );
          // setRedirectUrl(true);
        } else {

          let countReq = JSON.parse(window.sessionStorage.getItem("countReq"))
          changeProcessC2C(1)

          if (countReq != null && countReq <= 39) {

            window.sessionStorage.setItem(
              "countReq", countReq + 1
            );
            setTimeout(() => {
              requestTransfer()
            }, 2000);
          } else {
            setloading(false)
            setIsResult({ result: true, msg: data.message, isSuccess: 0 });
          }

          //  setIsResult({ result: true, msg: data.message, isSuccess: 0 });
        }
      } else {
        setIsResult({ result: true, msg: showErrorRequest(status), isSuccess: 0 });
      }
    } catch (error) {
      setIsResult({ result: true, msg: showErrorRequest(error), isSuccess: 0 });
    }
  };

  const requestGetRemainedTime = async () => {
    try {
      const { status, data } = await Api.getRemainedTime();
      if (status === 200) {
        let timeForm_ = new Date();
        timeForm_.setSeconds(
          timeForm_.getSeconds() + (data.remainedTime ? data.remainedTime : 5)
        );
        setTimeForm(timeForm_);
      }
    } catch (error) { }
  };



  React.useEffect(() => {
    if (endProcess) {


      setEndProcess(!endProcess)
      sessionStorage.setItem('countReq', null)
      resetProcessC2C(1)
      setloading(false)
      setIsResult({ ...isResult, result: true, msg: "لطفا دوباره تلاش کنید", isSuccess: 0 })
    }

  }, [endProcess])
  React.useEffect(() => {

    setWebsiteInfo(JSON.parse(sessionStorage.getItem("webSiteInfo")))
    if (JSON.parse(window.sessionStorage.getItem("isHomeC2C")) && !setIsTheme) {
      setIsTheme = true;
      setTheme(setLogo);

      setTimeout(() => {
        window.scrollTo(0, 2500);
      }, 900);
    }
  }, []);

  React.useEffect(() => {
    if (!JSON.parse(window.sessionStorage.getItem("isLastStepC2C")))
      setNavigate("loading")
    if (state) {

      setFormData(state);
      requestGetRemainedTime();
    }
  }, [state]);

  return (
    <Box
      style={
        { marginTop: "20px", marginBottom: 30 }}
    >



      <Grid
        container
        direction="row"
        justify="center"
        alignContent="center"
        alignItems="center"
        spacing={0}
        className={`${classes.boxMain} `}
        style={{ position: "relative" }}
      >
        {
          loading &&
          <DialogLoading isResult={loading} setEndProcess={setEndProcess} />
        }
        {
          //   <LoadingCircleProcess loading={loading} value={50}/>
        }
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          xs={12}
          className={classes.layoutTitle2}
          j
        >
          <Grid item className={classes.backgroundTimerOne}>
            {timeForm && (
              <Timer
                expiryTimestamp={timeForm}
                setSendMsgCode={(result_) => {
                  setIsResult({
                    ...isResult,
                    result: true,
                    msg: "زمان پرداخت شما به اتمام رسید",
                    isSuccess: 0,
                    endTimeFrom: true,
                  });
                }}
              />
            )}
          </Grid>
        </Grid>

        {formData?.methodDescription && <Grid
          item
          xs={10}
          className="layout_msg_manager"
          style={{ marginTop: "20px" }}
        >

          {formData?.methodDescription
            .split("\n")
            .map(function (item, idx) {
              return (
                <span key={idx}>
                  {replaceAllEnter(item)}
                  <br />
                </span>
              );
            })}

        </Grid>}

        <CardBank cardNumber={formData?.destinationCardNumber} nameCard={formData?.destinationCardOwner} nameBank={formData?.destinationCardBankName} />
        <Grid item xs={6} style={{ marginBottom: 10 }}>
          <Button
            size="large"
            type="submit"
            variant="contained"
            fullWidth
            color="secondary"
            className={!enableBtnCopy ? classes.copyBtn : classes.copyBtn2}
            onClick={() => {
              copy(formData?.destinationCardNumber)
              setEnableBtnCopy(true)
            }}
          >
            {!enableBtnCopy ? " کپی شماره کارت واریز" : "شماره کارت کپی شد"}
          </Button>
        </Grid>
        <Grid
          container
          xs={12}
          direction="row-reverse"
          justify="center"
          spacing={0}
        >
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            justifyContent="center"
            xs={10}
            className="un-selected"
            style={{
              marginLeft: 15,
              marginRight: 20,
              textAlign: "end",
              marginBottom: "10px",
              fontSize: "14px",
            }}
          >
            <Grid item style={{ color: "#000" }}>
              &nbsp; شماره کارت شما&nbsp;
            </Grid>
            <div
              style={{
                borderRadius: "50%",
                width: "4px",
                height: "4px",
                background: "rgba(0,0,0,0.5)",
                marginTop: "6px",
              }}
            ></div>
          </Grid>

          <Grid
            container
            direction="row"
            xs={10}
            justify="space-around"
            alignContent="center"
            alignItems="center"
            style={{ marginBottom: 10, marginTop: 0 }}
          >
            <Grid
              container
              xs={1}
              direction="row"
              justify="center"
              alignItems="center"
            >

              {formData?.userCardBankId && (
                <Avatar
                  alt="logo bank"
                  src={`./banksLogo/${formData?.userCardBankId}.png`}
                  className={classes.smallIcon}
                />
              )}
            </Grid>

            {formData?.userCardNumber &&
              getArrayNumberCard(formData.userCardNumber)?.map((value, index) => {
                return (
                  <Grid
                    item
                    className="layoutInputDiable paddingLayout un-selected"
                    justify="center"
                    style={{ textAlign: "center" }}
                  >
                    {" "}
                    {setNumberCard(value, index)}
                  </Grid>
                );
              })}
          </Grid>

          <Grid
            container
            direction="row"
            justify="flex-end"
            alignContent="center"
            justifyContent="center"
            className="un-selected"
            xs={10}
            style={{
              marginLeft: 15,
              marginRight: 20,
              textAlign: "end",
              marginBottom: "10px",
              fontSize: "14px",
              marginTop: "5px",
            }}
          >
            <Grid
              item
              style={{ color: "#000" }}
            >
              مبلغ&nbsp;
            </Grid>
            <div
              style={{
                borderRadius: "50%",
                width: "4px",
                height: "4px",
                background: "rgba(0,0,0,0.5)",
                marginTop: "6px",
              }}
            ></div>
          </Grid>

          <Grid
            item
            xs={10}
            className="layout_amount"
          >

            <span>{formData?.amount && convertNumberToPice(formData.amount)}</span>
            &nbsp;
            <span style={{ fontSize: "14px" }}>تومان</span>
          </Grid>

        </Grid>

        <Grid
          container
          xs={12}
          direction="row"
          justify="space-evenly"
          // justify="center"
          alignItems="center"
          justifyContent="space-evenly"
          className={classes.layoutButtones}
          style={{ paddingLeft: 20, paddingRight: 20 }}
        >
          <Grid item xs={4}>
            <Button
              size="large"
              type="submit"
              variant="contained"
              fullWidth
              color="secondary"
              className={`${classes.submit}`}
              style={{ background: "#d32f2f " }}
              onClick={handleBtnCancel}
            >
              انصراف
            </Button>
          </Grid>
          <div style={{ flex: 1 }}></div>
          <Grid item xs={7}>
            <Button
              size="large"
              type="submit"
              variant="contained"
              fullWidth
              color="secondary"
              className={classes.submit}
              onClick={handleBtnSubmit}
            >
              {websiteInfo?.C2C_paymentButtonText}
            </Button>
          </Grid>
        </Grid>
      </Grid>



      {isResult.isSuccess == 2 ? (
        <DialogSuccess
          isResult={isResult}
          handleCloseDialog={handleCloseDialogSuccess}
        />
      ) : isResult.isSuccess == 1 ? (
        <DialogWarring
          isResult={isResult}
          handleCloseDialog={handleCloseDialogWarring}
          handleCloseAndYesDialog={handleCloseAndBackDialogWarring}
          title={'لغو تراکنش'}
        />
      ) : (
        <DialogError
          isResult={isResult}
          handleCloseDialog={handleCloseDialogError}
          setNavigate={setNavigate}
        />
      )}
    </Box>
  );
};


export default connect(null, { changeProcessC2C, resetProcessC2C })(LastStepPage);