import axios from "axios";
import { convertNumbers2EnglishString } from '../tools/Tools'
import Crypto from 'crypto'
//import CryptoFa from 'crypto-fs'
import { v4 as uuid_v4 } from "uuid";

const getBaseUser = () => {
  let url_ = window.location.href.split("/");


  let baseUser = url_[url_.length - 3];
  return baseUser
}

const instance = axios.create({

  baseURL: `https://api.${window.location.hostname}/user/`,
  // validateStatus: (status) => {

  //   return status > 200;
  // }, 
  // withCredentials: false,
  // headers: {
  //   "Access-Control-Allow-Origin": "*",
  //   "Access-Control-Allow-Headers": "X-Requested-With"
  // }
  // headers: {
  //   "referer": "http://localhost:3001/",
  //   "origin": "http://localhost:3001"
  // }

  //timeout: 100,
});

// const errorComposer = (error) => {
//   return () => {
//     const statusCode = error.response ? error.response.status : null;
//     if (statusCode === 404) {
//       notifier.error('The requested resource does not exist or has been deleted')
//     }

//     if (statusCode === 401) {
//       notifier.error('Please login to access this resource')
//     }
//   }
// }

// axios.interceptors.response.use(undefined, function (error) {
//   // error.handleGlobally = errorComposer(error);


//   return Promise.reject(error);
// })

export const getRequestId = () => {


  let requestId = uuid_v4()
  if (sessionStorage.getItem("requestId") != requestId) {
    sessionStorage.setItem("requestId", requestId)
    return requestId
  } else {
    getRequestId()
  }
}

export const crp = (message) => {

  const publicKey = '-----BEGIN PUBLIC KEY-----\r\nMIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA0f5GvcAr0oUiItfCp44Y0qjNutRUMeYovo9KmVCnHSAfYL1L7si7rzFLLZyMcrB/b0uWe0USwqCJdN3Af6LYYDPKsE954CA/DlFNIuVEUTjHbEoIvCjaFwOvtOg8ZEGHz58ZTiUwHEjQAV+eIle88nNyQs1YJgembj1vSj9mxwQJa0lYb4SMUmQvUhTe1SfYqqsbbDEbiF8AM2HKjt1oP0FhrWhoin5NwrFBQW9Nwf/N4vMLLIeceRNuAekblQpmejB9CrOzKFUR0DCpWNLj8EB6LSFGMOn9II3CELZ4EPvr5jAdwQrF5GnOJwFw1Y1RQD+mJtWCWmsHlNuZvqopQPbM872bW5aAC0s+RpWn/TzvRAxMkhasTD6ecgHqY5bscNLwj/txlmsFex3UvJuwe4pcktzgEX+bnN9oqtFP2A+ba7zXIYMZqDhgs6N45dfwvI2+cqiOSg3T4rDI8ujTaAkqYTSoMmQeyxE9Sn6BeB3BfXdR1KZ+XrefkYVAL97PqHKtKQ8tyftfEcaqfm+YGqS/Uwcjy9x8p5imGtb0D6pJL2s2Xo2a8RISxc1qiRV+AtXUQAalQizkZ7UTMRWKSwPCUku0KBnk4/b5PwCHu5ExcdXHdX437BH+g9phNY8ad1/xmu2q6nFNOdwr/xv9MU9WluS4BSuSaX5GrjJf2iUCAwEAAQ==\r\n-----END PUBLIC KEY-----';
  var binaryData = Buffer.from(JSON.stringify(message))
  var encryptedMEssage = Crypto.publicEncrypt({ key: publicKey, padding: Crypto.constants.RSA_PKCS1_PADDING }, binaryData).toString('base64')
  return JSON.stringify({ data: encryptedMEssage })
}

const data = {

  checkSessionValidity: (sessionId) =>
    instance({
      method: "POST",

      url: "checkSessionValidity.php",

      data: crp({
        sessionId: sessionId, requestId: getRequestId()
      }),
    }),
  setPaymentMethod: (method) =>
    instance({
      method: "POST",
      url: `deposit/setPaymentMethod.php`,
      data: crp({
        sessionId: window.sessionStorage.getItem('sessionId'),
        method: method,
        requestId: getRequestId()
      }),

    }),

  cancelDeposit: () =>
    instance({
      method: "POST",
      url: `deposit/cancelDeposit.php`,
      data: crp({
        sessionId: window.sessionStorage.getItem('sessionId'),
        depositId: window.sessionStorage.getItem('depositId'),
        requestId: getRequestId()
      }),
    }),


  sendGatewayAuthenticationCode: () =>
    instance({
      method: "POST",
      url: `deposit/CPG/sendGatewayAuthenticationCode.php`,
      data: crp({
        sessionId: window.sessionStorage.getItem('sessionId'),
        depositId: window.sessionStorage.getItem('depositId'),
        requestId: getRequestId()

      }),
    }),

  //درخواست رمز دوم
  OTPRequest: (nationalCode, cardData, after) =>
    instance({
      method: "POST",
      url: `deposit/CPG/OTPRequest.php`,
      data: crp({
        sessionId: window.sessionStorage.getItem('sessionId'),
        depositId: window.sessionStorage.getItem('depositId'),
        nationalCode: nationalCode,
        cvv2: convertNumbers2EnglishString(cardData.cvv2),
        expireMonth: cardData.expireMonth,
        expireYear: cardData.expireYear,
        requestId: getRequestId(),
        ...after
      }),
    }),
  OTPRequestIPG: (captchaText) =>
    instance({
      method: "POST",
      url: `deposit/IPG/OTPRequest.php`,
      data: crp({
        requestId: getRequestId(),
        sessionId: window.sessionStorage.getItem('sessionId'),
        depositId: window.sessionStorage.getItem('depositId'),
        captchaText: captchaText
      }),
    }),
  captchaRequest: () =>
    instance({
      method: "POST",
      url: `deposit/IPG/getCaptcha.php`,
      data: crp({
        requestId: getRequestId(),
        sessionId: window.sessionStorage.getItem('sessionId'),
        depositId: window.sessionStorage.getItem('depositId')
      }),
    }),
  transfer: (cardData, extra) =>
    instance({
      method: "POST",
      url: `deposit/CPG/Transfer.php`,
      data: crp({
        sessionId: window.sessionStorage.getItem('sessionId'),
        depositId: window.sessionStorage.getItem('depositId'),
        cvv2: convertNumbers2EnglishString(cardData.cvv2),
        expireMonth: cardData.expireMonth,
        expireYear: cardData.expireYear,
        saveCardData: cardData.saveCardData,
        pin2: convertNumbers2EnglishString(cardData.pin2),
        requestId: getRequestId(),
        ...extra
      }),
    }),
  transferC2C: () =>
    instance({
      method: "POST",
      url: `deposit/C2C/Transfer.php`,
      data: crp({
        sessionId: window.sessionStorage.getItem('sessionId'),
        depositId: window.sessionStorage.getItem('depositId'),
        requestId: getRequestId()
      }),
    }),
  transferIPG: (cardData) =>
    instance({
      method: "POST",
      url: `deposit/IPG/Transfer.php`,
      data: crp({
        sessionId: window.sessionStorage.getItem('sessionId'),
        depositId: window.sessionStorage.getItem('depositId'),
        requestId: getRequestId(),
        cvv2: convertNumbers2EnglishString(cardData.cvv2),
        expireMonth: cardData.expireMonth,
        expireYear: cardData.expireYear,
        saveCardData: cardData.saveCardData,
        pin2: convertNumbers2EnglishString(cardData.pin2),
        captchaText: cardData.captchaText,

      }),
    }),
  validateGatewayAuthenticationCode: (gatewayAuthenticationCode) =>
    instance({
      method: "POST",
      url: `deposit/CPG/validateGatewayAuthenticationCode.php`,
      data: crp({
        sessionId: window.sessionStorage.getItem('sessionId'),
        depositId: window.sessionStorage.getItem('depositId'),
        gatewayAuthenticationCode: gatewayAuthenticationCode,
        requestId: getRequestId()
      }),
    }),
  addCard: (cardNumber) =>
    instance({
      method: "POST",
      url: "addCard.php",
      data: crp({
        sessionId: window.sessionStorage.getItem('sessionId'),
        method: window.sessionStorage.getItem("method"),
        cardNumber: cardNumber,
        requestId: getRequestId()
      }),
    }),
  startPayment: (info) =>
    instance({
      method: "POST",
      url: `deposit/CPG/startPayment.php`,
      data: crp({
        sessionId: window.sessionStorage.getItem('sessionId'),
        //   method:'CPG',
        amount: info.amount,
        cardNumber: info.cardNumber,
        gatewayId: info.gatewayId,
        mobileNumber: convertNumbers2EnglishString(info.mobileNumber),
        requestId: getRequestId(),
        registrationNationalCode: info.registrationNationalCode
      }),


    }),
  startPaymentC2C: (info) =>
    instance({
      method: "POST",
      url: `deposit/C2C/startPayment.php`,
      data: crp({
        sessionId: window.sessionStorage.getItem('sessionId'),
        amount: info.amount,
        cardNumber: info.cardNumber,
        requestId: getRequestId()
      }),
    }),
  startPaymentIPG: (info) =>
    instance({
      method: "POST",
      url: `deposit/IPG/startPayment.php`,
      data: crp({
        sessionId: window.sessionStorage.getItem('sessionId'),
        amount: info.amount,
        cardNumber: info.cardNumber,
        requestId: getRequestId()
      }),
    }),

  getAdminAuth() {
    const session = window.sessionStorage.getItem("adminAuth");
    const local = localStorage.getItem("adminAuth");
    const result = local !== "null" ? local : session;
    return result;
  },


  showDashboardStats: (_adminAuth) =>
    instance({
      method: "POST",
      url: "dashboard/showDashboardStats.php",
      data: crp({
        adminAuth: _adminAuth,
        requestId: getRequestId()
      }),
    }),
  getRemainedTime: (_adminAuth) =>
    instance({
      method: "POST",
      url: `deposit/getRemainedTime.php`,
      data: crp({
        sessionId: window.sessionStorage.getItem('sessionId'),
        depositId: window.sessionStorage.getItem('depositId'),
        requestId: getRequestId()
      }),
    }),
  getCaptcha: () =>
    instance({
      method: "POST",
      url: `deposit/CPG/getCaptcha.php`,
      data: crp({
        sessionId: window.sessionStorage.getItem('sessionId'),
        depositId: window.sessionStorage.getItem('depositId'),
        requestId: getRequestId()
      }),
    }),

  showDepositList: (_adminAuth, _page, _recordsPerPage) =>
    // instance({
    //   method: "POST",
    //   url: "deposits/showDepositList.php",
    //   data: JSON.stringify({
    //     adminAuth: _adminAuth,
    //    page: _page,
    //    recordsPerPage: _recordsPerPage,
    //   }),
    // })

    instance.post("deposits/showDepositList.php", JSON.stringify({
      adminAuth: _adminAuth,
      page: _page,
      recordsPerPage: _recordsPerPage,
    }))


  ,

  searchDepositList: (dataSearch) =>
    instance({
      method: "POST",
      url: "deposits/searchDepositList.php",
      data: JSON.stringify(dataSearch),
    }),



};

export default data