import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Dialog } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "@material-ui/icons/Close";
import { Navigate } from "react-router-dom";
import ContactSupportIcon from '@material-ui/icons/ArrowBack';
import { normalizeText } from "../../../../tools/Tools";


const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="down"
      ref={ref}
      {...props}
      timeout={{ enter: 450, exit: 450 }}
    />
  );
});

const useStyles = makeStyles((theme) => ({

  btnHelp: {

    backgroundColor: "#039BE5 !important",
    borderRadius: "6px",
    color: "#fff !important",
    boxShadow: "inset 0 1px 1px #C1E6F9, 0 0 3px #039BE5",
    border: "none",
    "&:hover": {
      // color: "#039BE5 !important",
      border: "none !important",
      backgroundColor: "#03a5f4 !important",
      color: "#fff !important",
      // backgroundColor: "#039BE5",

    },

  },
  dialogLayout: {
    "& .MuiDialog-paper": {
      overflowY: "visible !important",
    },

    background: "#0009",
  },
  circleIcon: {

    borderRadius: "50%",
    width: "80px",
    height: "80px",
    // border:"3px solid",
    position: "absolute",
    zIndex: "150",
    alignItems: "center",
    justifyItems: "center",
    alignContent: "center",
    border: "5px solid #ffffff",
    background: "#d32f2f",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  successDialog: {
    borderLeft: "5px solid #4CAF50",
    marginTop: "10px",
    background: "#4CAF5040",
    padding: "4px",
    textAlign: "left",
    paddingLeft: "5px",
    fontSize: "14px",
    marginTop: "10px",
    borderRadius: "8px",
  },
  iconDialog: {
    fontSize: "52px",
    color: "white",
  },
  titleDialog: {
    fontSize: "20px",
    color: "#3b3b3b",
    marginTop: "20px",
    textAlign: "center",
  },
  textDialog: {
    textAlign: "center",
    marginTop: "20px",
    width: "100%",
    color: "#757575",
    fontSize: "14px",
    marginRight: "15px",
    marginLeft: "15px",
  },
  layoutButtonDialog: {
    marginTop: "30px",
    color: "#4d4d4d",
    minWidth: "250px",
  },
  buttonDialog: {
    background: "#d32f2f",
    "&:hover": {
      background: "#a32222",
    },
  },
}));
const DialogError = ({ isResult, handleCloseDialog, setNavigate }) => {
  const classes = useStyles();

  const [showBackBtn, setShowBackBtn] = React.useState(false)
  useEffect(() => {
    if (isResult && isResult.msg) {
      if (isResult.msg.includes("back"))
        setShowBackBtn(true)
    }
  }, [isResult])
  return (
    <Dialog
      open={isResult.result}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCloseDialog}
      className={classes.dialogLayout}
    >


      <div style={{ height: "60px", justifyContent: "center", alignItems: "center", position: "relative" }}>
        <Grid
          container
          className={classes.circleIcon}
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <CloseIcon className={classes.iconDialog} />
        </Grid>
      </div>

      <Grid
        container
        direction="column"
        justify="center"
        alignContent="center"
        justifyContent="center"
        alignItems="center"

        style={{ color: "dark", minWidth: "250px" }}
      >
        <Grid item className={classes.titleDialog}>
          {" "}
          خطا
        </Grid>
        <div
          className={`${classes.textDialog} un-selected`}
          style={{ direction: "rtl" }}
        >
          {" "}
          {isResult.msg ? normalizeText(isResult.msg.replace('back', '')) : ""}{" "}


        </div>
        {
          showBackBtn &&
          <Button
            size="small"
            type="submit"
            variant="outlined"
            className={classes.btnHelp}
            color="primary"
            style={{ marginTop: 20 }}
            onClick={() => setNavigate("home")}
            startIcon={<ContactSupportIcon />}

          >
            &nbsp;  بازگشت به مرحله اول
            &nbsp;
          </Button>
        }
      </Grid>


      <Grid
        container
        direction="row"
        justify="center"
        className={classes.layoutButtonDialog}
      >
        <Button
          size="large"
          type="submit"
          variant="contained"
          fullWidth
          color="secondary"
          className={classes.buttonDialog}
          onClick={handleCloseDialog}
        >
          بستن
        </Button>
      </Grid>
    </Dialog>
  );
};
DialogError.propTypes = {
  isResult: PropTypes.object.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
};
export default DialogError;
