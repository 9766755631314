import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import imageBack from "../../../../assets/images/bg-overlay-home1.png";
import nextImage from "../../../../assets/images/next.png";
import warringImage from "../../../../assets/images/warning.png";

export const useStyles = makeStyles((theme) => ({

  btnHelp: {

    backgroundColor: "transparent",
    borderRadius: "6px",
    color: "#039BE5 !important",
    boxShadow: "inset 0 1px 1px #C1E6F9, 0 0 3px #039BE5",
    border: "none",
    "&:hover": {
      // color: "#039BE5 !important",
      border: "none !important",
      backgroundColor: "#039BE5 !important",
      color: "#fff !important",
      // backgroundColor: "#039BE5",

    },

  },
  paper: {
    display: "flex",
    flexDirection: "column",
    //  alignItems: "center",
    //  alignContent:"center",
  },
  layout: {
    alignContent: "center",
    justifyItems: "center",
    textAlign: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    "& span": {
      fontFamily: "IRANSans",
    },
    marginTop: theme.spacing(4),
    bottom: theme.spacing(-2),
    marginRight: theme.spacing(8),
    marginLeft: theme.spacing(8),
    borderRadius: "8px",
    background: "#4CAF50",
    color: "white !important",
    boxShadow: "0 4px 8px   rgba(0,0,0,0.4)",
  },
  imageWarring: {
    backgroundImage: `url(${warringImage})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
    width: "45px",
    height: "45px",
    backgroundPosition: "center",
  },
  bodyAmount2: {
    textAlign: "center",
    fontSize: "18px",
    color: "#000",
    borderRadius: "8px",
    background: "#e8e8e8",
    direction: "ltr",
  },
  titleAmount: {
    textAlign: "center",
    fontSize: "11px",
    color: "grey",
  },
  btnAddCard: {
    backgroundColor: "#039BE5",
    borderRadius: "6px",
    color: "white !important",
    boxShadow: "inset 0 1px 1px rgba(0,0,0,.02), 0 0 8px rgba(57, 57, 57, 0.2)",
    border: "none",
    "&:hover": {
      // color: "#039BE5 !important",
      border: "none !important",
      backgroundColor: "#027ab5 !important",
    },

    backgroundImage: `url(${imageBack})`,
    backgroundSize: "contain",
    backgroundRepeat: "repeat",
    backgroundPosition: "left",
  },

  imageSubmit: {
    backgroundImage: `url(${nextImage})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    width: "25px",
    height: "25px",
    backgroundPosition: "center",
  },
  getway: {
    //  boxShadow: "0.5rem 0.5rem 2rem 0 rgba(0,0,0,0.9)",

    // boxShadow:' inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(76, 175, 79, 0.6)',
    boxShadow: "0 4px 8px    rgba(0,0,0,0.2)",
    borderRadius: "20px",
    background: "#f4f5f7",
    minHeight: "35px",
    // border:"1px dotted rgba(76, 175, 79)"
  },
  iconSelected: {
    justifySelf: "center",
    marginBottom: "-10px",
    marginLeft: "5px",
    marginRight: "4px",
  },
  getwaySelected: {
    boxShadow: "0 4px 8px   rgba(0,0,0,0.2)",
    borderRadius: "20px",
    minHeight: "35px",
    // border:"1px solid rgba(0,0,0,0.05)",
    background: "#039BE5",
    "& div": {
      color: "#fff !important",
    },
  },

  lineGeyWay: {
    background: "rgba(0,0,0,0.1)",
    width: "2px",
    minHeight: "35px",
    marginRight: "3px",
  },
  lineGeyWay2: {
    border: "1px dotted #ededed",
    // width: "2px",
    minHeight: "45px",
    marginRight: "3px",
  },
  backBtn: {
    "& span": {
      fontFamily: "IRANSans",
    },
    borderRadius: theme.spacing(20),
    paddingRight: theme.spacing(0.5),

  },
  marginMenucardNumber: {
    marginRight: 5,
    marginLeft: 5,
    marginTop: 5,
  },
  boxMain: {
    boxShadow: "0.5rem 0.5rem 2rem 0 rgba(0,0,0,0.2)",
    background: "#fff",
    WebkitBorderRadius: theme.spacing(1),
    borderRadius: theme.spacing(1.5),
    padding: 0,
    margin: 0,
  },
  warringAddCard: {
    background: "#FFEEBC",
    width: "100%",
    padding: theme.spacing(1),
    borderLeft: "7px solid #FFCA28",
    // direction: "ltr",
    borderRadius: "5px",
    fontSize: theme.spacing(1.6),
  },
  box: {
    padding: theme.spacing(2),
  },
  anim: {
    opacity: 1,
    transform: "scale(1)",
    transition: "opacity 800ms,transform 800ms",
  },
  input: {
    marginLeft: theme.spacing(5),
    margin: theme.spacing(3),
  },
  layoutTitle: {
    paddingTop: theme.spacing(1),
    // // background:"linear-gradient(176deg, rgba(144,164,174,1) 0%, rgba(38,50,56,1) 100%)",
    textAlign: "center",
    // justifyContent: "center",
    color: "white",
    // paddingBottom: theme.spacing(1),
    // bottom: theme.spacing(-30),
    borderBottomRightRadius: theme.spacing(2.5),
    borderTopLeftRadius: theme.spacing(0.99),
    paddingLeft: theme.spacing(1),
    //  backgroundColor: "rgba(57, 57, 57,1)",

    // backgroundImage: `url(${imageBack})`,
    // backgroundSize:"contain",
    // backgroundRepeat:'no-repeat',
    // backgroundPosition: 'left',
  },
  inputAmount: {
    border: "none",
    borderBottom: "none !important",
    background: "transparent !important",

    "& div:focus": {
      borderBottom: "none !important",
    },
    "& :focus": {
      borderBottom: "none !important",
      background: "transparent !important",
      //   background: "#e6e6e6 !important",
    },
    "& div::before": {
      borderBottom: "none !important",
      background: "transparent !important",
    },
    "& ::after": {
      borderBottom: "none !important",
      background: "transparent !important",
    },
    margin: theme.spacing(0),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  small2: {
    width: "30px",
    height: "30px",
    marginRight: "2px",
    marginLeft: "5px",
  },
  gatewayName: {
    direction: "rtl",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    fontSize: "13px",
    color: "grey",
    alignSelf: "center",
    marginBottom: "-4px",
  },
  menuItem: {
    "&:hover": {
      // backgroundColor: "rgba(102, 175, 233, 0.4)",
      backgroundColor: "rgba(3, 155, 229, 0.2)",
    },
    "&:focus": {
      //   backgroundColor: "rgba(102, 175, 233, 0.8)",
      backgroundColor: "rgba(3, 155, 229, 0.3)",
      color: "dark",
      //  borderRight: "2px solid  rgb(57, 57, 57)",
      //  borderLeft: "2px solid  rgb(57, 57, 57)",
      //  borderRadius:"10px",
    },
  },
  dialog: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));
