import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Button, Dialog } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import MobileFriendlyIcon from "@material-ui/icons/MobileFriendly";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Cancel';
import SyncIcon from '@material-ui/icons/Sync';
import SecurityIcon from '@material-ui/icons/Security';
import WidgetsIcon from '@material-ui/icons/Widgets';
import DoneIcon from '@material-ui/icons/Done';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
//import CheckIcon from '@material-ui/icons/Check';
import CheckIcon from '@material-ui/icons/Check';
const Transition = React.forwardRef(function Transition(props, ref) {
  return (
    <Slide
      direction="down"
      ref={ref}
      {...props}
      timeout={{ enter: 450, exit: 450 }}
    />
  );
});

const useStyles = makeStyles((theme) => ({
  dialogLayout: {
    "& div>div": {
      overflowY: "visible !important",
      //  background: "#eee"
    },
    // "& div>.MuiDialog-paperWidthSm": {
    //   //    minWidth: "400px !important",
    // },
    "& div>.MuiDialog-paper": {
      //    minWidth: "400px !important",
      background: "#e7e7e7"
    },
    background: "#0009",
  },
  circleIcon: {

    borderRadius: "50%",
    width: "80px",
    height: "80px",
    // border:"3px solid",
    position: "absolute",
    zIndex: "150",
    alignItems: "center",
    justifyItems: "center",
    alignContent: "center",
    border: "5px solid #e7e7e7",
    background: "#4CAF50",
    left: "50%",
    transform: "translate(-50%,-50%)",


  },
  successDialog: {
    borderLeft: "5px solid #4CAF50",
    marginTop: "10px",
    background: "#4CAF5040",
    padding: "4px",
    textAlign: "left",
    paddingLeft: "5px",
    fontSize: "14px",
    marginTop: "10px",
    borderRadius: "8px",
  },
  iconDialog: {
    fontSize: "40px",
    color: "white",
  },
  titleDialog: {
    fontSize: "16px",
    color: "#3b3b3b",
    marginTop: "15px",
    textAlign: "center",
  },
  textDialog: {
    textAlign: "center",
    marginTop: "10px",
    width: "100%",
    color: "#757575",
    marginLeft: "15px",
    marginRight: "15px",
    maxWidth: "300px",
    paddingRight: "15px",
    paddingLeft: "15px",
    alignContent: "center",
    alignItems: "center",
    justify: "center",
    justifyContent: "center",
    justifySelf: "center",
    fontSize: "12px",
    marginBottom: "15px",
  },
  layoutButtonDialog: {
    marginTop: "20px",
    color: "#4d4d4d",
    minWidth: "250px",
  },
  image2: {
    height: "70px",
    width: "270px",
    borderRadius: "8px !important",
    marginTop: "5px",
    marginBottom: "5px",
  },
  btn: {
    margin: 5,
    "&  .MuiButton-endIcon": {
      // paddingBottom: 10,
      // background: "#e7e7e7"
      marginRight: 2,
      paddingBottom: 3
      //  marginLeft: 5
      //  marginRight: "-10px",
    },

  }
}));
const DialogCode = (props) => {
  const classes = useStyles();
  const {
    isResult,
    handleCloseDialog,
    handleBackDialog,
    handleReload,
    handleSubmitDialogCode,
    confirmCodeImageId,
    position,
    data,
    reload
  } = props;
  const [input, setInput] = React.useState();
  const [error, setError] = React.useState(false);
  const [listImages, setListImage] = React.useState([
    false, false, false, false, false, false, false, false, false
  ]);

  const handleChangeInput = (e) => {
    setInput(e.target.value);
  };
  const clickImage = (e) => {
    // e.taget.
    const list = listImages;
    list[e.target.id] = !listImages[e.target.id];
    setListImage([...list])
  }
  const submit = () => {
    const hasTrue = listImages.some(value => value === true);
    if (hasTrue)
      handleSubmitDialogCode(listImages);
    else {
      setError(true)
      setTimeout(() => {
        setError(false)
      }, 3000);
    }
  }
  useEffect(() => {

    //if (reload)
    setListImage([false, false, false, false, false, false, false, false, false])
    setInput("")
    setError(false)
  }, [reload, data])

  return (
    <Dialog
      open={isResult}
      TransitionComponent={Transition}
      keepMounted
      maxWidth={position == "confirmCode" ? "xs" : "xs"}
      onClose={handleCloseDialog}
      className={classes.dialogLayout}
    >

      <div style={{ height: "60px", justifyContent: "center", alignItems: "center", position: "relative" }}>



        <Grid
          container
          className={classes.circleIcon}
          direction="row"
          justify="center"
          alignItems="flex-start"
        >
          <WidgetsIcon className={classes.iconDialog} />


        </Grid>
        <IconButton aria-label="CloseIcon" onClick={handleBackDialog} style={{ position: "absolute", right: 1 }}>
          <CloseIcon style={{ fontSize: 34 }} />
        </IconButton>

      </div>
      {position === "captchaCode_Shaparak_1" ?
        <>
          <Grid
            container
            direction="column"
            alignContent="center"
            justifyContent="center"
            alignItems="center"
            className="un-selected"
            style={{ color: "dark", marginBottom: 30, fontSize: 22 }}
          >
            <Grid item  >
              {" "}
              تصاویر شامل
              <span style={{ color: "red" }} className={reload ? "noColor loader" : ""}> {data?.captchaCategory} </span>
              را انتخاب کنید
            </Grid>

          </Grid>

          <Grid container spacing={0} justifyContent="center" alignItems="center" className="hightCaptcha" style={{ paddingLeft: 5, paddingRight: 5, direction: "rtl", }}>
            {listImages.map((value, index) => (<Grid item xs={4} style={{ paddingLeft: 2, paddingRight: 2, position: "relative" }}>

              {reload ? <div className="loader hightCaptchaImg" style={{ borderRadius: 4 }}>  </div> :
                <img
                  alt={"img_" + index}
                  //  className={classes.image2}
                  style={{ borderRadius: 4, width: "100%", cursor: "pointer" }}
                  src={data?.captchaImages[index]}
                  id={index}
                  className={value ? "selectImage" : "selectImageOut"}
                  onClick={clickImage}

                ></img>}
              {value && <CheckCircleIcon style={{ color: "#03A9F4", backgroundColor: "#eee", borderRadius: 50, fontSize: 40, position: 'absolute', right: -1, top: -5 }} />}</Grid>))}
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="center">
            {
              error && <Grid item style={{ color: "red", fontSize: 15 }}>
                لطفا حداقل یک تصویر را انتخاب کنید
              </Grid>
            }
          </Grid>
          <Grid
            container
            direction="row"
            //   alignContent="space-between"
            //  justifyContent="space-between"
            alignItems="center"
            // className={classes.layoutButtonDialog}
            style={{ paddingRight: 15, paddingLeft: 5, marginTop: 10, marginBottom: 10 }}
          >
            <Grid item xs={3}>

              <IconButton size="small" onClick={handleReload}>
                <SyncIcon style={{ color: "#757575", fontSize: 35 }} />
              </IconButton></Grid>
            <Grid item xs={9}><Button
              size="large"
              type="submit"
              variant="contained"
              fullWidth
              color="secondary"
              onClick={submit}

              className={classes.btn}
              endIcon={<CheckIcon />}
            >
              تأیید
            </Button></Grid>
          </Grid>
        </> :
        <>
          <Grid
            container
            direction="column"
            alignContent="center"
            justifyContent="center"
            className="un-selected"
            style={{ color: "dark", minWidth: "250px" }}
          >
            <Grid item className={classes.titleDialog}>
              {" "}
              درخواست کد تأیید
            </Grid>
            <Grid container justify="center" alignContent="center" direction="row">
              {" "}
              <Grid item xs={12} style={{ justifyContent: "center", alignItems: "center", flexDirection: "row", display: "flex" }}>
                <img
                  item
                  className={classes.image2}
                  src={`./confirmCodeImage/${confirmCodeImageId}.jpg`}
                ></img>
              </Grid>
              <Grid item className={`${classes.textDialog}  un-selected`}>
                کد تأیید که برای شما ارسال شده است را وارد کنید
              </Grid>
            </Grid>


          </Grid>
          <Grid
            item
            xs={12}
            className="layoutInput"
            style={{ marginRight: 15, marginLeft: 15, marginBottom: 15 }}
          >
            <input
              className="inputCard"
              type="tel"
              pattern="[0-9]*"
              onInput={handleChangeInput}
              value={input}
              maxLength={9}
              placeholder={"کد تأیید"}
              style={{ fontSize: "12px" }}
            />
          </Grid>
          <Grid
            container
            direction="row"
            justify="center"
            className={classes.layoutButtonDialog}
          >
            <Button
              size="large"
              type="submit"
              variant="contained"
              fullWidth
              color="secondary"
              onClick={() => handleSubmitDialogCode(input)}
              style={{ margin: 5 }}
            >
              ثبت
            </Button>

          </Grid>
        </>


      }
    </Dialog>
  );
};
DialogCode.propTypes = {
  isResult: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  codeConfirm: PropTypes.func.isRequired,
};
export default DialogCode;
