import React, { useState, useEffect } from "react";
import { Grid, Button, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import InputPhone from "./InputPhone";
import InputCard2 from '../nationalCode/InputCard2'
import { Navigate, Link } from "react-router-dom";

import useStyles from "./Style";
import {
  setTheme,
  convertNumbers2EnglishString,
} from "../../../../tools/Tools";
import Api, { crp, getRequestId } from "../../../../api/Api";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import Timer from "../lastStepPage/Timer";
import WarningIcon from "@material-ui/icons/Warning";
import DialogSuccess from "../dialogs/DialogSuccess";
import DialogError from "../dialogs/DialogError";
import DialogLoading2 from "../../c2c/dialogs/DialogLoading2";
import { connect } from "react-redux";
import { setHideFooter } from '../../../../redux/actions'
import { showErrorRequest } from './../../../../tools/Tools';
import axios from "axios";


let time;
let phoneSet = "";
let loackRequestCancel = false;
const PhonePage = ({ setLogo, setNavigate }) => {
  const classes = useStyles();
  const [phone, setPhone] = useState(null);
  const [nationalCode, setNationalCode] = useState(null);
  const [loading, setloading] = useState(false);
  const [registrationNationalCode_required, setRegistrationNationalCode_required] = useState();

  const [toNext, setToNext] = useState(false);
  const [endProcess, setEndProcess] = React.useState(false);
  const myRef = React.useRef(null);
  const [isShowBoxCode, setIsShowBoxCode] = useState(false);
  const [sendMsgCode, setSendMsgCode] = React.useState(true);
  const [
    gatewayAuthenticationCode,
    setGatewayAuthenticationCode,
  ] = React.useState("");
  const [isResult, setIsResult] = useState({
    result: false,
    msg: "",
    isSuccess: 2, // 0:error 1: warring 2: success
  });



  React.useEffect(() => {
    if (!JSON.parse(window.sessionStorage.getItem("isPhonePage")))
      setNavigate("loading")
    setPhone(phoneSet);
    setTheme(setLogo);
  }, []);

  React.useEffect(() => {
    if (endProcess) {
      setloading(false);
    }
  }, [endProcess]);


  const showError = (msg) => {
    setloading(false);
    setIsResult({
      ...isResult,
      result: true,
      msg: msg,
      isSuccess: 0,
    });
  };

  const handleButtonSubmit = () => {
    setloading(true);
    //

    if (toNext) {
      setTimeout(() => {
        requestValidateGatewayAuthenticationCode();
      }, 600);
    } else {
      setTimeout(() => {

        if (phone) {
          if (phone.length == 11) {
            requestStartPayment();
          } else {
            showError("شماره همراه را درست وارد کنید");
          }
        } else {
          showError("ابتدا شماره همراه را وارد کنید");
        }
      }, 600);
    }
  };

  const handleCloseDialogError = () => {
    setIsResult({ ...isResult, result: false });
    if (isResult.isSuccess == 2) {
      setInfoStartPayMent(10);
    }
  };

  // درخواست اطلاعات فرم
  const requestValidateGatewayAuthenticationCode = async () => {
    try {
      const { status, data } = await Api.validateGatewayAuthenticationCode(
        convertNumbers2EnglishString(gatewayAuthenticationCode)
      );

      if (status === 200) {
        if (data.success) {
          setloading(false);


          if (data.FormData.isNationalCodeRequired) {
            let nationalCode = data.FormData.lastEnteredNationalCode;
            if (nationalCode && nationalCode != "null") {
              window.sessionStorage.setItem("nationalCode", nationalCode);
              window.sessionStorage.setItem("isLastStep", true);
              setNavigate("last-step-page", data.FormData);
            } else {
              window.sessionStorage.setItem("isNationalcode", true);
              setNavigate("NationalCodePage", data.FormData);
            }
          } else {
            window.sessionStorage.setItem("isLastStep", true);
            setNavigate("last-step-page", data.FormData);
          }
        } else showError(data.message);
      } else showError(showErrorRequest(status));
    } catch (error) {
      showError(showErrorRequest(error));
    }
  };

  const requestCancelDeposit = async () => {
    loackRequestCancel = true
    try {
      const { data } = await Api.cancelDeposit();
      loackRequestCancel = false
    } catch (error) { }
  };

  const handleChangeGatewayAuthenticationCode = (e) => {
    setGatewayAuthenticationCode(convertNumbers2EnglishString(e.target.value));
  };

  const handleSendAgainCode = () => {
    //  setSendMsgCode(true);
    setloading(true);
    setTimeout(() => {
      requestSendGatewayAuthenticationCode();
    }, 300);

    // setIsResult({ ...isResult, result: true,   msg: "کد برای شما ارسال شده است",
    // isSuccess:2 });
    // if(isResult.isSuccess==2){

    //   setInfoStartPayMent(10);
    // }
  };

  const handleCloseDialogSuccess = () => {
    setIsResult({ ...isResult, result: false });
    time = new Date();
    time.setSeconds(time.getSeconds() + Number(isResult?.timer));
    setToNext(true);
    setSendMsgCode(true);
    setTimeout(() => {
      myRef.current.focus();
    }, 700);
  };

  // ارسال مجدد کد تایید
  const requestSendGatewayAuthenticationCode = async () => {
    try {
      const { status, data } = await Api.sendGatewayAuthenticationCode();
      setloading(false);
      if (status === 200) {
        if (data.success) {
          if (data.FormData && data.FormData.isNationalCodeRequired) {

            window.sessionStorage.setItem("isNationalcode", true);
            setNavigate("NationalCodePage", data.FormData);
          } else {
            setIsResult({
              ...isResult,
              result: true,
              msg: data.message,
              isSuccess: 2,
              timer: data.GatewayAuthenticationCode_setCountdownTimer,
            });
          }
        } else setIsResult({ result: true, msg: data.message, isSuccess: 0 });
      } else
        showErrorRequest(status)
    } catch (error) {
      setIsResult({ result: true, msg: showErrorRequest(error), isSuccess: 0 });
    }
  };

  const handleBackBtn = () => {
    setNavigate("home");
    const depositId = JSON.parse(window.sessionStorage.getItem("depositId"));
    if (depositId && !loackRequestCancel) {
      requestCancelDeposit();
    }
  };

  const setInfoStartPayMent = (timeCount) => {
    setloading(false);

    setIsResult({
      ...isResult,
      result: true,
      msg: "کد برای شما ارسال شده است",
      isSuccess: 2,
      timer: timeCount,
    });
  };
  const requestStartPayment = async () => {
    try {
      let list = JSON.parse(sessionStorage.getItem("listServer"));
      list.mobileNumber = phone;
      if (nationalCode && nationalCode.length > 2)
        list.registrationNationalCode = nationalCode;

      sessionStorage.setItem("phonePay", phone);
      phoneSet = phone;

      const { status, data } = await Api.startPayment(list)



      if (status === 200) {
        if (data.success) {
          window.sessionStorage.setItem("depositId", data.depositId);

          if (data.isSmsAuthenticationRequired) {
            setInfoStartPayMent(data.GatewayAuthenticationCode_setCountdownTimer);
          } else {

            if (data.FormData.isNationalCodeRequired) {
              let nationalCode = data.FormData.lastEnteredNationalCode;
              if (nationalCode && nationalCode != "null") {
                window.sessionStorage.setItem("nationalCode", nationalCode);
                window.sessionStorage.setItem("isLastStep", true);
                setNavigate("last-step-page", data.FormData);
              } else {
                window.sessionStorage.setItem("isNationalcode", true);
                setNavigate("NationalCodePage", data.FormData);
              }
            } else {
              window.sessionStorage.setItem("isLastStep", true);
              setNavigate("last-step-page", data.FormData);
            }
          }
        } else {

          if (data.registrationNationalCode_required) {
            setloading(false);
            sessionStorage.setItem("phonePay", phone)
            setRegistrationNationalCode_required(data.registrationNationalCode_required)
          } else

            showError(data.message);
        }
      } else
        showErrorRequest(status)
    } catch (error) {

      // showError(showErrorRequest(error.toJSON().status));
      showError(showErrorRequest(error));
    }
  };
  const handleClickEnter = (e) => {
    if (e.key === "Enter") {
      handleButtonSubmit();
    }
  };
  const requestAuthe = async () => {
    try {
      const { status, data } = await Api.sendGatewayAuthenticationCode(phone);

      if (status === 200) {
        if (data.success) {
          setIsShowBoxCode(true);
          // setIsResult({ ...isResult, result: true,msg:data.message,isSuccess:2 });
          // window.sessionStorage.setItem("userCards", JSON.stringify(data.userCards));
          // window.sessionStorage.setItem("isMsgAddCard", true);
          // setTimeout(() => {

          // //   if(JSON.parse(window.sessionStorage.getItem("addCard_StepStep")))
          // //   setIsNextStep(true)
          // //  else
          // //   setMsgAddCard(true)
          // }, 1500);
        } else {
          setIsResult({ ...isResult, result: true, msg: data.message });
        }
      } else
        setIsResult({ ...isResult, result: true, msg: showErrorRequest(status) });
    } catch (error) {
      setIsResult({ ...isResult, result: true, msg: showErrorRequest(error) });
    }
  };



  return (
    <Box style={{ paddingTop: "50px" }}>


      <Grid
        container
        spacing={0}
        className={`${classes.boxMain} ${loading && "blurBackground"}`}
        justify="center"
        alignContent="center"
        alignItems="center"
        style={{ position: "relative" }}
      >
        {loading && (
          <DialogLoading2
            isResult={loading}
            setEndProcess={setEndProcess}
            startProcess={0}
          />
        )}


        <Grid container direction="row" justify="flex-start" xs={8}>
          <Button
            size="small"
            type="submit"
            variant="text"
            color="primary"
            onClick={handleBackBtn}
            className={classes.backBtn}
            startIcon={<ArrowBackIosIcon />}
          >
            بازگشت
          </Button>
        </Grid>

        <Grid
          item
          className={`${classes.layoutTitle} backgroundTitle un-selected`}
          xs={4}
        >
          احراز هویت
        </Grid>

        <Grid
          xs={10}
          className={classes.warringAddCard}
          style={{ marginTop: 20 }}
        >
          کد ملی مالک کارت و شماره موبایل باید یکسان باشد
        </Grid>



        <InputPhone
          setPhone={setPhone}
          phone={phone}
          toNext={toNext}
          setToNext={setToNext}
          enterClick={handleButtonSubmit}
        />


        {registrationNationalCode_required &&
          <>

            <InputCard2
              setPhone={setNationalCode}
              toNext={toNext}
              setToNext={setToNext}
              enterClick={handleButtonSubmit}
            />
            <Grid
              xs={10}
              className={classes.errorAddCard}
              style={{ fontSize: 13 }}
            >
              <span style={{ fontWeight: "900 !important", fontFamily: "IRANSans !important", fontSize: "14px" }}> کاربر گرامی</span>
              <br />

              به دلیل الزام تطابق کد ملی صاحب کارت و شماره موبایل برای استفاده از این درگاه (که از طرف بانک مرکزی اعمال شده است)، کد ملی شما تنها در جهت انجام عملیات پرداخت استفاده شده و در اختیار هیچ شخص ثالثی قرار نخواهد گرفت

            </Grid>
          </>}
        {toNext && (
          <>
            <Grid
              container
              direction="row"
              justify="flex-end"
              alignContent="center"
              justifyContent="center"
              xs={9}
              style={{
                marginLeft: 15,
                marginRight: 20,
                marginTop: 20,
                textAlign: "end",
                fontSize: "14px",
              }}
            >
              <Grid item> &nbsp; کد تأیید &nbsp;</Grid>
              <div
                style={{
                  borderRadius: "50%",
                  width: "4px",
                  height: "4px",
                  background: "rgba(0,0,0,0.5)",
                  marginTop: "9px",
                }}
              ></div>
            </Grid>

            <Grid
              xs={10}
              container
              direction="row"
              justify="center"
              spacing={0}
            >
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignContent="center"
                xs={11}
                className="layoutInput"
                style={{ marginTop: 5, padding: 0, marginBottom: 5 }}
              >
                {sendMsgCode ? (
                  <Grid
                    container
                    direction="row"
                    xs={6}
                    justify="flex-start"
                    justifyContent="center"
                    alignContent="center"
                    alignItems="center"
                  >
                    <Grid
                      container
                      direction="row"
                      xs={6}
                      className={classes.layoutTimer}
                      justify="center"
                    >
                      <Timer
                        expiryTimestamp={time}
                        setSendMsgCode={setSendMsgCode}
                      />
                    </Grid>

                    <Grid item className="line"></Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    direction="row"
                    xs={6}
                    justify="center"
                    justifyContent="center"
                    alignContent="center"
                    alignItems="center"
                    className={classes.layoutSubmitCode}
                  >
                    <Button
                      size="small"
                      type="submit"
                      variant="text"
                      color="secondary"
                      fullWidth
                      onClick={handleSendAgainCode}
                      className={`${classes.submitPassword} ${classes.hoverHide}`}
                    >
                      ارسال مجدد کد
                    </Button>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <input
                    className="inputCard2"
                    type="tel"
                    onInput={handleChangeGatewayAuthenticationCode}
                    value={gatewayAuthenticationCode}
                    placeholder="کد تأیید"
                    maxLength={20}
                    tabIndex={4}
                    ref={myRef}
                    onKeyDown={handleClickEnter}
                    style={{ textAlign: "center", width: "100%" }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        <Grid
          container
          xs={12}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Button
            size="large"
            type="submit"
            variant="contained"
            fullWidth
            color="secondary"
            className={classes.submit}
            onClick={handleButtonSubmit}
          >
            <Grid
              container
              xs={11}
              direction="row"
              justify="flex-end"
              alignContent="center"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Grid item xs={6} style={{ textAlign: "center" }}>
                ادامه
              </Grid>
              <Grid
                item
                className="line"
                style={{
                  background: "#fff",
                  marginLeft: "15px",
                  marginRight: "15px",
                }}
              ></Grid>
              <Grid item xs={3} className={classes.imageSubmit}></Grid>
            </Grid>
          </Button>
        </Grid>
      </Grid>

      {isResult.isSuccess == 2 ? (
        <DialogSuccess
          isResult={isResult}
          handleCloseDialog={handleCloseDialogSuccess}
          setNavigate={setNavigate}
        />
      ) : (
        <DialogError
          isResult={isResult}
          handleCloseDialog={handleCloseDialogError}
          setNavigate={setNavigate}
        />
      )}
    </Box>
  );
};
export default PhonePage;
