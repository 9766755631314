import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Player } from "@lottiefiles/react-lottie-player";
import anim from "../../../assets/anim/loading.json";
import styled, { keyframes } from "styled-components";
import { flipInX } from "react-animations";
import Api from "../../../api/Api";
import DialogError from "../cpg/dialogs/DialogError";
import DialogError2 from "../cpg/dialogs/DialogError2";
import { connect } from "react-redux";
import { setWebsiteInfo } from '../../../redux/actions'
import { showErrorRequest } from '../../../tools/Tools';


let loackRequest = false
const Loading = ({ setLogo, setWebsiteInfo, setNavigate }) => {


  const [isOpenStory, setIsOpenStory] = React.useState(false);

  const [isResult, setIsResult] = useState({
    result: false,
    msg: "",
    isSuccess: 0,
  });

  const handleCloseDialog = () => {
    if (isResult.backTosite) {
      window.location = isResult.address;
    } else {
      if (!loackRequest)
        request();
    }

    setIsResult({ ...isResult, result: false });
  };

  useEffect(() => {
    setComponent()
  }, []);

  const setComponent = () => {
    let url = sessionStorage.getItem("url_");
    let sessionId = sessionStorage.getItem("sessionId");


    localStorage.clear();
    sessionStorage.clear();
    sessionStorage.setItem("url_", url);
    sessionStorage.setItem("sessionId", sessionId)



    if (sessionId == null || sessionId == "null" || sessionId == undefined) {
      let url_ = url.split("/");
      let sessionId_ = url_[url_.findIndex((v) => v == "#") + 1];
      sessionStorage.setItem("sessionId", sessionId_);
    } else sessionStorage.setItem("sessionId", sessionId);

    let url_ = url.split("/");
    let sessionId_ = url_[url_.findIndex((v) => v == "#") + 1];


    if (sessionId_ && sessionId_.length > 45)
      sessionStorage.setItem("sessionId", sessionId_);
    else sessionStorage.setItem("sessionId", sessionId);

    //  if (document.getElementById("copyRight"))
    //  document.getElementById("copyRight").style.display = "none"
    setTimeout(() => {
      if (!loackRequest)
        request();
    }, 500);
  }

  const request = async () => {
    loackRequest = true
    try {

      const { status, data } = await Api.checkSessionValidity(sessionStorage.getItem("sessionId"));

      if (status === 200) {
        // if (data && data.websiteInfo && !data.websiteInfo.showCopyright)
        //   document.getElementById("copyRight").style.display = "none";
        // else {
        //   document.getElementById("copyRight").style.display = "inline";
        // }
        setWebsiteInfo(data.websiteInfo)
        if (data.success) {
          window.sessionStorage.setItem(
            "webSiteInfo",
            JSON.stringify(data.websiteInfo)
          );
          window.sessionStorage.setItem("sessionType", data.sessionType);
          window.sessionStorage.setItem("isNationalCodeRequired2", data.isNationalCodeRequired);

          if (data.sessionType == "deposit") {
            window.sessionStorage.setItem(
              "banksCardPrefixes",
              JSON.stringify(data.banksCardPrefixes)
            );
            if (data.availablePaymentMethods.length == 0) {
              window.sessionStorage.setItem("CPG", false);
              window.sessionStorage.setItem("C2C", false);
              window.sessionStorage.setItem("IPG", false);
              window.sessionStorage.setItem("isSelectMethod", true);

              setNavigate("selectMethod")
            } else if (data.availablePaymentMethods.length > 1) {
              window.sessionStorage.setItem("messageTitle", data.message);
              window.sessionStorage.setItem("CPG", data.availablePaymentMethods.includes("CPG"));
              window.sessionStorage.setItem("C2C", data.availablePaymentMethods.includes("C2C"));
              window.sessionStorage.setItem("IPG", data.availablePaymentMethods.includes("IPG"));
              window.sessionStorage.setItem("availablePaymentMethods", JSON.stringify(data.availablePaymentMethods));
              window.sessionStorage.setItem("isSelectMethod", true);

              setNavigate("selectMethod")
            } else if (data.availablePaymentMethods[0] == "CPG") {
              window.sessionStorage.setItem("CPG", true);

              requestPaymentMethod("CPG");
            } else if (data.availablePaymentMethods[0] == "IPG") {
              window.sessionStorage.setItem("IPG", true);
              requestPaymentMethod("IPG");
            } else {
              window.sessionStorage.setItem("C2C", true);
              requestPaymentMethod("C2C");
            }
          } else {
            if (data.userCanViewWithdrawalForm) {
              window.sessionStorage.setItem("isHomePageWithdrawal", true);
              window.sessionStorage.setItem(
                "isLastRequest",
                data.FormData.isLastRequest
              );

            } else {
              setIsResult({
                ...isResult,
                result: true,
                msg: data.withdrawalFormErrorMessage,
                isSuccess: 1,
                backTosite: true,
                address: data.websiteAddress,
              });
            }
          }
        } else if (data.isUnderMaintenance) {
          window.sessionStorage.setItem(
            "webSiteInfo",
            JSON.stringify(data.websiteInfo)
          );
          window.sessionStorage.setItem("isUpdate", true);

          window.sessionStorage.setItem("message", data.message);
          setNavigate("update")
        } else {
          window.sessionStorage.setItem("isFielad", true);
          window.sessionStorage.setItem(
            "webSiteInfo",
            JSON.stringify(data.websiteInfo)
          );
          window.sessionStorage.setItem("message", data.message);
          setNavigate("fielad")

        }
      } else
        setIsResult({
          ...isResult,
          result: true,
          msg: showErrorRequest(status),
        });
      loackRequest = false
    } catch (error) {
      loackRequest = false



      //      setIsResult({ ...isResult, result: true, msg: showErrorRequest(error.toJSON().status) });
      setIsResult({ ...isResult, result: true, msg: showErrorRequest(error) });
    }
  };

  const requestPaymentMethod = async (method) => {
    try {
      window.sessionStorage.setItem("method", method);
      const { status, data } = await Api.setPaymentMethod(method);


      if (status === 200) {
        if (data.success) {

          window.sessionStorage.setItem("helpMessage", data.helpMessage);
          window.sessionStorage.setItem(
            "isDefaultAmountExists",
            data.isDefaultAmountExists
          );
          if (data.userCards.length < 1) {
            if (!data.isUserAllowedToOperateByUndefinedCard) {
              window.sessionStorage.setItem("addCard", true);
              window.sessionStorage.setItem("btn_back_addCard", false);
              window.sessionStorage.setItem("isSelectMethod", true);
              setNavigate("addCard")
            } else {
              if (data.isUserAllowedToAddCard) {
                if (data.isPendingCardExists) {
                  window.sessionStorage.setItem("PayNot", true);
                  window.sessionStorage.setItem(
                    "undefinedCardPaymentErrorMessage",
                    data.pendingCardErrorMessage
                  );
                  setNavigate("PayNotPage")
                } else {
                  window.sessionStorage.setItem("addCard", true);
                  window.sessionStorage.setItem("btn_back_addCard", false);
                  window.sessionStorage.setItem("addCard_StepStep", true);
                  setNavigate("addCard")
                }
              } else {
                window.sessionStorage.setItem("PayNot", true);
                window.sessionStorage.setItem(
                  "undefinedCardPaymentErrorMessage",
                  data.undefinedCardPaymentErrorMessage
                    ? data.undefinedCardPaymentErrorMessage
                    : "شما هیچ کارت تایید شده ای ندارید"
                );
                setNavigate("PayNotPage")
              }
            }
          } else {
            window.sessionStorage.setItem(
              "isPendingCardExists",
              data.isPendingCardExists
            );
            window.sessionStorage.setItem(
              "pendingCardErrorMessage",
              data.pendingCardErrorMessage
            );
            window.sessionStorage.setItem(
              "isPriceListExists",
              data.isPriceListExists
            );

            window.sessionStorage.setItem(
              "priceList",
              JSON.stringify(data.priceList)
            );
            window.sessionStorage.setItem(
              "userCards",
              JSON.stringify(data.userCards)
            );
            window.sessionStorage.setItem(
              "isUserAllowedToPayByUndefinedCard",
              data.isUserAllowedToPayByUndefinedCard
            );
            window.sessionStorage.setItem(
              "isUserAllowedToAddCard",
              data.isUserAllowedToAddCard
            );

            window.sessionStorage.setItem("defaultAmount", data.defaultAmount);
            window.sessionStorage.setItem(
              "minimumDepositAmount",
              data.minimumDepositAmount
            );
            window.sessionStorage.setItem(
              "maximumDepositAmount",
              data.maximumDepositAmount
            );
            if (method == "CPG") {
              window.sessionStorage.setItem(
                "isNationalCodeRequired",
                data.isNationalCodeRequired
              );

              window.sessionStorage.setItem("showNote", data.showNote);
              window.sessionStorage.setItem("noteMessage", data.noteMessage);
              window.sessionStorage.setItem("isHome", true);
              setNavigate("home")
            } else if (method === "IPG") {
              window.sessionStorage.setItem("showDisableVPNBox", data.showDisableVPNBox);
              window.sessionStorage.setItem("disableVPNBox_Message", data.disableVPNBox_Message);
              window.sessionStorage.setItem("ishomeIPG", true);
              setNavigate("homeIPG");
            } else {
              window.sessionStorage.setItem("isHomeC2C", true);

              setNavigate("homeC2C")

            }
          }
        } else
          setIsResult({
            ...isResult,
            result: true,
            msg: data.message,
          });
      } else
        setIsResult({
          ...isResult,
          result: true,
          msg: showErrorRequest(status),
        });
    } catch (error) {
      setIsResult({ ...isResult, result: true, msg: showErrorRequest(error) });
    }
  };

  return (
    <>


      {!isResult.result && (
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          justifyContent="center"
          spacing={2}
        >
          <Grid item xs={12}>
            <Player
              autoplay
              loop
              src={anim}
              style={{ height: "200px", width: "200px" }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ textAlign: "center", color: "#000", marginTop: "-15%" }}
          >
            ... درحال بارگذاری اطلاعات
          </Grid>
        </Grid>
      )}

      {isResult.isSuccess != 1 ? (
        <DialogError
          isResult={isResult}
          handleCloseDialog={handleCloseDialog}
          setNavigate={setNavigate}
        />
      ) : (
        <DialogError2
          isResult={isResult}
          handleCloseAndYesDialog={() => {
            //  setIsResult({result:false,isSuccess:0})
            setIsOpenStory(!isOpenStory);
          }}
          setNavigate={setNavigate}
        />
      )}

    </>
  );
};



export default connect(null, { setWebsiteInfo })(Loading);

