import React, { useEffect, useState } from "react";
import { Grid, Container, Typography, Button, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Player, Controls } from "@lottiefiles/react-lottie-player";
import anim from "../../../../assets/anim/anim_active.json";

import { Navigate, Link } from "react-router-dom";

import styled, { keyframes } from "styled-components";
import { flipInX } from "react-animations";
import { useStyles } from './style'
import { containsNumber, replaceAllEnter } from '../../../../tools/Tools'

import { setTheme } from "../../../../tools/Tools";





let setIsTheme = false;
const UpdatePage = ({ setLogo, setNavigate }) => {
  const classes = useStyles();

  const [infoSite, setInfoSite] = useState({});
  const [click, setClick] = useState(false);

  React.useEffect(() => {
    if (!window.sessionStorage.getItem("isFielad"))
      setNavigate("loading")
    if (!setIsTheme) {
      setIsTheme = true;
      setTheme(setLogo);
    }
  }, []);

  useEffect(() => {
    const info = window.sessionStorage.getItem("webSiteInfo");


    if (info)
      setInfoSite(JSON.parse(info))

  }, [])
  return (

    <Box style={{ marginTop: "40px", marginBottom: 40 }}>
      <Grid container spacing={0} className={classes.boxMain}>
        <Grid
          item
          xs={12}
          style={{
            textAlign: "center",
            marginTop: "23px",
            fontFamily: "IRANSans",
          }}
        >
          {window.sessionStorage
            .getItem("message")
            .split("\n")
            .map(function (item, idx) {
              return (
                <span key={idx}>
                  {replaceAllEnter(item)}
                  <br />
                </span>
              );
            })}


        </Grid>
        <div style={{ display: 'none' }} id="sessionId">{sessionStorage.getItem("sessionId")}</div>
        <Grid item xs={12}>
          <Player
            autoplay
            loop
            src={anim}
            style={{ height: "300px", width: "300px" }}
          />
        </Grid>

        <Grid
          container
          xs={12}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Button
            size="large"
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            className={classes.submit}
            startIcon={<ArrowBackIosIcon />}
          >


            <a href={infoSite?.websiteAddress}
              style={{ color: "white", textDecoration: "none", direction: "rtl", fontSize: "14px" }}  >   بازگشت به {containsNumber(infoSite?.websiteName) ? <span className="unSetFont"> {infoSite?.websiteName}</span> : infoSite?.websiteName}  </a>


          </Button>
        </Grid>

      </Grid>
    </Box>

  );
};
export default UpdatePage;
